import { mediaScreen, toClamp } from 'assets/style/functions';
import { Colors, Fonts } from 'assets/style/variables';
import connexionFullBG from 'assets/images/connexion-full-bg.jpg';
import styled from 'styled-components';

export const GeneralHeader = styled.header`
  position: absolute;
  top: ${toClamp(55)};
  left: ${toClamp(111)};
  ${mediaScreen(`left: 50%; transform: translateX(-50%);`, 768)};
`;
export const PrincipalLogo = styled.img`
  width: ${toClamp(247)};
`;

export const ConnexionHeader = styled.header`
  position: relative;
  width: 51.5%;
  background-color: ${Colors.black};
  order: 1;
  min-height: 100vh;
  padding-right: 106px;
  overflow: hidden;
  &::after {
    content: '';
    width: 980px;
    height: 100vh;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${connexionFullBG});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left top;
    ${mediaScreen(
      `
    width: ${toClamp(1596)};
    height: ${toClamp(1006)};
    `,
      768
    )}
  }
  ${mediaScreen(
    `
   min-height: 503px;
    height: fit-content;
    width: 100%;
    padding-right: 66px;
    `,
    768
  )}
`;
export const HeadingTextWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${toClamp(1)} solid ${Colors.white};
  height: 390px;
  margin: 0 auto;
  background-color: ${Colors.black};
  z-index: 1;
  margin-left: 97px;
  /* padding: 0 ${toClamp(86)}; */
  ${mediaScreen(
    `
    height: 156px;
    margin-left: ${toClamp(158)};
    `,
    768
  )}
`;

export const ConnexionHeading = styled.h1`
  font-size: ${toClamp(91)};
  line-height: ${toClamp(95)};
  font-family: ${Fonts.boldMblz};
  white-space: pre-line;
  color: ${Colors.white};
  strong {
    display: block;
    color: ${Colors.orange};
    white-space: nowrap;
    ${mediaScreen(
      `
    display: inline;
    `,
      768
    )}
  }
  ${mediaScreen(
    `
      font-size: ${toClamp(72)};
      line-height: ${toClamp(76)};
    `,
    1200
  )}
  ${mediaScreen(
    `
      font-size: ${toClamp(48)};
      line-height: ${toClamp(52)};
    `,
    900
  )}
  ${mediaScreen(
    `
      white-space: normal;
      font-size: ${toClamp(78)};
      line-height: ${toClamp(82)};
    `,
    768
  )}
    ${mediaScreen(
    `
    font-size: 30px;
    line-height: 34px;
    `,
    580
  )}
  ${mediaScreen(
    `
    font-size: 21px;
    line-height: 25px;
    `,
    500
  )}
`;
export const BreakOnMoblie = styled.span`
  ${mediaScreen(
    `
    display: block;
    `,
    768
  )}
`;

export const ConnexionLogo = styled.img`
  position: relative;
  width: 293px;
  height: 293px;
  z-index: 1;
  ${mediaScreen(
    `
    width: ${toClamp(316)};
    height: ${toClamp(317)};
    `,
    768
  )}
`;

export const ConnexionSmallLogo = styled.img`
  position: relative;
  width: 294px;
  height: 98px;
  margin: 101px 0px 0px 97px;
  z-index: 1;
  ${mediaScreen(
    `
    width: ${toClamp(478)};
    height: ${toClamp(158)};
    margin: 83px 0 0 79px;
    `,
    768
  )}
`;

export const DashboardHeader = styled.header`
  width: ${toClamp(413)};
  display: flex;
  justify-content: flex-start;
  min-height: 100vh;
  flex-direction: column;
  ${({ isMenuShown }) =>
    mediaScreen(
      `transform: ${isMenuShown ? 'translateX(0)' : 'translateX(-100%)'};
      box-shadow: ${isMenuShown && '0px 0px 20px rgba(0, 0, 0, 0.75)'};
      transition: all 0.7s ease-in-out;
      width: ${toClamp(440)};
      position: fixed;
      background-color: ${Colors.white};
      z-index: 99999;
      top: 0;
      `,
      768
    )}
`;

export const MenuLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${toClamp(54)} ${toClamp(32)} 0 ${toClamp(52)};
`;

export const DashboardLogo = styled.img`
  width: ${toClamp(247)};
  display: block;
`;

export const IconImg = styled.img`
  width: ${toClamp(32)};
  display: none;
  ${mediaScreen(`display: block;`, 768)};
`;

export const muiDeconnexionButton = {
  position: 'fixed',
  bottom: 0,
  width: toClamp(413),
  fontSize: toClamp(22),
  lineHeight: toClamp(25),
  height: toClamp(84),
  textTransform: 'capitalize',
  color: Colors.primaryText,
  borderRadius: 0,
  borderColor: 'transparent',
  borderTop: `1px solid ${Colors.generalBgColor}`,
  paddingLeft: toClamp(110),
  justifyContent: 'flex-start',
  fontFamily: Fonts.regularMblz,
  fontWeight: 'initial',
  transition: 'background-color 0.25s, color 0.25s',
  textDecoration: 'underline',
  ':hover': {
    textDecoration: 'underline',
    backgroundColor: Colors.orange,
    borderColor: 'transparent',
    color: Colors.white,
    '& img': {
      filter: 'brightness(0) invert(1)',
      transition: 'filter 0.25s',
    },
  },
};

export const muiDeconnexionIcon = {
  position: 'absolute',
  left: toClamp(54),
  height: toClamp(32),
};
