import { mediaScreen, toClamp } from 'assets/style/functions';
import { Colors, Fonts } from 'assets/style/variables';
import styled from 'styled-components';
export const SuccessWrapper = styled.div`
  ${({ isConnexion }) =>
    !isConnexion &&
    `
    background-color: ${Colors.white};
    border-radius: ${toClamp(20)};
    padding: ${toClamp(52)} ${toClamp(212)} ${toClamp(120)};
    text-align: center;
    margin-top: ${toClamp(32)};
  ${mediaScreen(
    `padding: ${toClamp(52)} ${toClamp(96)} ${toClamp(120)};`,
    960
  )};
}
  .MuiButtonBase-root {
    ${mediaScreen(`width: 100%;`, 420)};
  }
    `}
`;
export const SuccessParagraph = styled.p`
  padding-top: ${toClamp(32)};
  font-size: ${toClamp(28)};
  line-height: ${toClamp(32)};
  color: black;
  font-family: ${Fonts.boldMblz};
  max-width: ${toClamp(1012)};
  margin: 0 auto;
`;
export const SuccessEmailParagraph = styled.p`
  padding: ${toClamp(56)} 0 ${toClamp(16)};
  max-width: ${toClamp(1012)};
  margin: 0 auto;
  font-size: ${toClamp(20)};
  line-height: ${toClamp(20)};
  color: ${Colors.secondaryText};
  font-family: ${Fonts.regularMblz};
`;
export const SuccessEmailParagraphBold = styled(SuccessEmailParagraph)`
  font-size: ${toClamp(32)};
  line-height: ${toClamp(40)};
  font-weight: 600;
`;
export const SuccessSpan = styled.span`
  color: #00bddb;
  padding: ${toClamp(6)};
`;
export const SuccessEmailSpan = styled(SuccessSpan)`
  display: block;
`;
export const SuccessEmailStrong = styled(SuccessEmailSpan)`
  font-weight: 600;
  font-size: ${toClamp(28)};
`;
