import ScrollToTop from 'components/scroll-to-top';
import { AppRouter } from './routers';

export const App = ({ isUnderMaintenance }) => {
  return (
    <>
      <ScrollToTop />
      <AppRouter isUnderMaintenance={isUnderMaintenance} />
    </>
  );
};
