export const content = {
  generalConditions: {
    content: 'Conditions générales',
    path: '/conditions-generales',
  },
  help: {
    content: "Besoin d'aide",
    path: '/espace-aide',
  },
};
