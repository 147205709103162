import { Success } from 'components/success';
import { Layouts } from 'layouts';
import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PrivateRoute } from './private-route';
import { PublicRoute } from './public-route';
import { Suspense } from './suspense';

const SignIn = lazy(() =>
  import('pages/connexion/sign-in').then(module => ({
    default: module.SignIn,
  }))
);
const SignUp = lazy(() =>
  import('pages/connexion/sign-up').then(module => ({
    default: module.SignUp,
  }))
);
const AccountVerification = lazy(() =>
  import('pages/connexion/account-verification').then(module => ({
    default: module.AccountVerification,
  }))
);
const NewPassword = lazy(() =>
  import('pages/connexion/new-password').then(module => ({
    default: module.NewPassword,
  }))
);
const ResetPassword = lazy(() =>
  import('pages/connexion/reset-password').then(module => ({
    default: module.ResetPassword,
  }))
);
const ResetPasswordVerification = lazy(() =>
  import('pages/connexion/reset-password-verification').then(module => ({
    default: module.ResetPasswordVerification,
  }))
);
const DemandeDetail = lazy(() =>
  import('pages/home/demande-detail').then(module => ({
    default: module.DemandeDetail,
  }))
);
const DemandeInfoSuccess = lazy(() =>
  import('pages/home/demande-information-success').then(module => ({
    default: module.DemandeInfoSuccess,
  }))
);
const DemandeSAV = lazy(() =>
  import('pages/home/demande-sav').then(module => ({
    default: module.DemandeSAV,
  }))
);
const DemandeSAVSuccess = lazy(() =>
  import('pages/home/demande-sav-success').then(module => ({
    default: module.DemandeSAVSuccess,
  }))
);
const EspaceAide = lazy(() =>
  import('pages/home/espace-aide').then(module => ({
    default: module.EspaceAide,
  }))
);
const Historique = lazy(() =>
  import('pages/home/historique').then(module => ({
    default: module.Historique,
  }))
);
const MesContrats = lazy(() =>
  import('pages/home/mes-contrats').then(module => ({
    default: module.MesContrats,
  }))
);
const MesNotifications = lazy(() =>
  import('pages/home/mes-notifications').then(module => ({
    default: module.MesNotifications,
  }))
);
const MonProfil = lazy(() =>
  import('pages/home/mon-profil').then(module => ({
    default: module.MonProfil,
  }))
);
const Reclamation = lazy(() =>
  import('pages/home/reclamation').then(module => ({
    default: module.Reclamation,
  }))
);
const ReclamationSuccess = lazy(() =>
  import('pages/home/reclamation-success').then(module => ({
    default: module.ReclamationSuccess,
  }))
);
const ReglementImpayes = lazy(() =>
  import('pages/home/reglement-impayes').then(module => ({
    default: module.ReglementImpayes,
  }))
);
const ReglementImpayesSuccess = lazy(() =>
  import('pages/home/reglement-impayes-success').then(module => ({
    default: module.ReglementImpayesSuccess,
  }))
);
const PageIntrouvable = lazy(() =>
  import('pages/page-introuvable').then(module => ({
    default: module.PageIntrouvable,
  }))
);
const SiteUnderMaintenance = lazy(() =>
  import('pages/site-en-maintenance').then(module => ({
    default: module.SiteUnderMaintenance,
  }))
);

const GeneralConditons = lazy(() =>
  import('pages/home/conditions-generales').then(module => ({
    default: module.GeneralConditons,
  }))
);

export const AppRouter = ({ isUnderMaintenance }) => {
  return (
    <>
      {isUnderMaintenance ? (
        <Layouts isUnderMaintenance={isUnderMaintenance}>
          <SiteUnderMaintenance />
        </Layouts>
      ) : (
        <Routes>
          <Route
            element={
              <PrivateRoute>
                <Layouts isDashboardPage />
              </PrivateRoute>
            }
          >
            <Route
              path="/mes-contrats"
              element={<Suspense component={<MesContrats />} />}
            />

            <Route
              path="/demande-sav"
              element={<Suspense component={<DemandeSAV />} />}
            />
            <Route
              path="/demande-sav/successful-sav"
              element={<Suspense component={<DemandeSAVSuccess />} />}
            />
            <Route
              path="/reclamation"
              element={<Suspense component={<Reclamation />} />}
            />
            <Route
              path="/reclamation/successful-reclamation"
              element={<Suspense component={<ReclamationSuccess />} />}
            />
            <Route
              path="/demande-sav/successful-info"
              element={<Suspense component={<DemandeInfoSuccess />} />}
            />
            <Route
              path="/historique"
              element={<Suspense component={<Historique />} />}
            />
            <Route
              path="/reglement-impayes"
              element={<Suspense component={<ReglementImpayes />} />}
            />
            <Route
              path="/reglement-impayes/successful-payment"
              element={<Suspense component={<ReglementImpayesSuccess />} />}
            />
            <Route
              path="/demande-sav/:id"
              element={<Suspense component={<DemandeDetail />} />}
            />
            <Route
              path="/reclamation/:id"
              element={<Suspense component={<DemandeDetail />} />}
            />
            <Route
              path="/mes-notifications"
              element={<Suspense component={<MesNotifications />} />}
            />
            <Route
              path="/mon-profil"
              element={<Suspense component={<MonProfil />} />}
            />
            <Route
              path="/espace-aide"
              element={<Suspense component={<EspaceAide />} />}
            />
            <Route
              path="/"
              element={<Suspense component={<Navigate to="/mes-contrats" />} />}
            />
            <Route
              path="/conditions-generales"
              element={<Suspense component={<GeneralConditons />} />}
            />
          </Route>
          <Route
            element={
              <PublicRoute>
                <Layouts isConnexionPage />
              </PublicRoute>
            }
          >
            <Route
              path="/sign-in"
              element={<Suspense component={<SignIn />} />}
            />
            <Route
              path="/sign-up"
              element={<Suspense component={<SignUp />} />}
            />
            <Route
              path="/account-verification"
              element={<Suspense component={<AccountVerification />} />}
            />
            <Route
              path="/code-verification"
              element={<Suspense component={<ResetPasswordVerification />} />}
            />
            <Route
              path="/successful-email"
              element={
                <Suspense
                  component={
                    <Success
                      isConnexion
                      isEmail
                      value="Je veux me connecter"
                      to="/sign-in"
                    />
                  }
                />
              }
            />
            <Route
              path="/successful-registration"
              element={
                <Suspense
                  component={
                    <Success
                      isConnexion
                      value="Revenir à la page de connexion"
                      to="/sign-in"
                    />
                  }
                />
              }
            />
            <Route
              path="/successful-reset-password"
              element={
                <Suspense
                  component={
                    <Success
                      isConnexion
                      passwordUpdated
                      value="Je veux me connecter"
                      to="/sign-in"
                    />
                  }
                />
              }
            />
            <Route
              path="/reset-password"
              element={<Suspense component={<ResetPassword />} />}
            />
            <Route
              path="/new-password/:token"
              element={<Suspense component={<NewPassword />} />}
            />
          </Route>
          <Route element={<Suspense component={<Layouts isGeneral />} />}>
            <Route
              path="*"
              element={<Suspense component={<PageIntrouvable />} />}
            />
          </Route>
        </Routes>
      )}
    </>
  );
};
