import { useContext } from 'react';
import { AppContext } from 'context/AppContext';
import * as Styled from './style';

const mainStyle = {
  general: Styled.GeneralMain,
  underMaintenance: Styled.UnderMaintenanceMain,
  connexionPage: Styled.ConnexionMain,
  dashboard: Styled.DashboardMain,
};

export const Main = ({
  isUnderMaintenance,
  isConnexionPage,
  isDashboardPage,
  children,
}) => {
  const appContext = useContext(AppContext);

  const selectedMain = isUnderMaintenance
    ? 'underMaintenance'
    : isConnexionPage
    ? 'connexionPage'
    : isDashboardPage
    ? 'dashboard'
    : 'general';

  const MainComponent = mainStyle[selectedMain];

  return <MainComponent onClick={appContext.closeMenu}>{children}</MainComponent>;
};
