import styled from 'styled-components';
import { Colors, Fonts } from 'assets/style/variables';
import { mediaScreen, toClamp } from 'assets/style/functions';

export const ConnexionIntroHeading = styled.h2`
  font-size: ${toClamp(26)};
  line-height: ${toClamp(29)};
  font-family: ${Fonts.boldMblz};
  text-transform: uppercase;
  ${mediaScreen(
    `
    font-size: 26px;
    line-height: 31px;
    `,
    768
  )}
`;
export const ConnexionIntroParagraph = styled.p`
  font-size: ${toClamp(18)};
  line-height: ${toClamp(20)};
  padding: ${toClamp(12)} 0 ${toClamp(42)};
  color: ${Colors.secondaryText};
  font-family: ${Fonts.regularMblz};
  ${mediaScreen(
    `
    font-size: 18px;
    line-height: 20px;
    `,
    768
  )}
`;
