import styled from 'styled-components';
import { Colors } from 'assets/style/variables';
import { mediaScreen, toClamp } from 'assets/style/functions';

export const OrangeCircleWrapper = styled.div`
  background-color: ${Colors.orange};
  width: ${toClamp(153)};
  height: ${toClamp(153)};
  border-radius: 50%;
  margin: ${({ hasIconImg }) =>
      hasIconImg ? `${toClamp(24)}` : `${toClamp(42)}`}
    auto ${toClamp(12)};
  padding: ${({ hasIconImg }) => (hasIconImg ? '' : `${toClamp(36)}`)};
  overflow: hidden;
  ${mediaScreen(`transform: scale(0.75);`, 768)};
`;

export const muiOrangeCircleButton = {
  width: '100%',
  height: '100%',
  padding: toClamp(36),
  border: 0,
  backgroundColor: 'transparent',
  ':hover': {
    border: 0,
    backgroundColor: 'transparent',
  },
  '& .MuiButton-startIcon': {
    width: '100%',
    height: '100%',
    margin: 0,
    '& .material-icons ': {
      width: '100%',
      height: '100%',
    },
  },
};
