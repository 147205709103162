import { createGlobalStyle } from "styled-components";
import { fontFaces } from "./font-faces";
import { toClamp } from "./functions";
import { Colors } from "./variables";

export const GlobalStyle = createGlobalStyle`
 ${fontFaces}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
#root {
  min-height: 100vh;
}
body {
  color: ${Colors.primaryText};
}
a {
  text-decoration: none;
}
form {
  .MuiFormControl-root:not(:last-of-type) {
    margin-bottom: ${toClamp(25)};
  }
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
input[type=number] {
    -moz-appearance: textfield;
}
.flex {
  display: flex;
  justify-content: center;
}
.full-height {
  height: 100%;
}
.relative {
  position: relative;
}
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
`;
