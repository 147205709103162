import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import AppProvider from 'context/AppContext';
import { App } from './app';
import * as Styled from './assets/style/globalStyle';
import { MuiTheme } from './assets/style/mui-theme';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Styled.GlobalStyle />
    <AppProvider>
      <ThemeProvider theme={MuiTheme}>
        <Router>
          <App isUnderMaintenance={false} thereIsNotifications={true} />
        </Router>
      </ThemeProvider>
    </AppProvider>
  </React.StrictMode>
);
