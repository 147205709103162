export const Colors = {
  primaryText: '#303030',
  secondaryText: '#888888',
  gray: '#D9D9D9',
  grayTwo: '#E2E2E2',
  dragAndDropBorder: '#acacac',
  generalBgColor: '#F6F6F6',
  orange: '#f45000',
  skyBlue: '#00BDDB',
  lightSkyBlue: '#00bedb0f',
  red: '#c3261f',
  green: '#1fc25d;',
  black: '#000000',
  white: '#ffffff',
  errorColor: '#fb0508',
  lightErrorColor: 'rgba(251, 5, 8, 0.05)',
};
export const Fonts = {
  lightGraphieW05: "'GraphieW05-Light', sans-serif",
  regularGraphieW05: "'GraphieW05-Regular', sans-serif",
  semiBoldGraphieW05: "'Graphie W05', sans-serif",
  boldGraphieW05: "'GraphieW05-Bold', sans-serif",
  druk: "'Druk', sans-serif",
  graphie: "'Graphie', sans-serif",
  regularMblz: "'Mblz-Regular', sans-serif",
  boldMblz: "'Mblz-Bold', sans-serif",
  extraBoldMblz: "'Mblz-Extra-Bold', sans-serif",
};
