import { Outlet } from 'react-router-dom';
import { Header } from './header';
import { Main } from './main';
import * as Styled from './style';
import { Footer } from './footer';

export const Layouts = ({
  isGeneral,
  isUnderMaintenance,
  isConnexionPage,
  isDashboardPage,
  children,
}) => {
  return (
    <Styled.RootWrapper
      isGeneral={isGeneral}
      isUnderMaintenance={isUnderMaintenance}
    >
      <Header
        isGeneral={isGeneral}
        isConnexionPage={isConnexionPage}
        isDashboardPage={isDashboardPage}
      />
      <Main
        isUnderMaintenance={isUnderMaintenance}
        isGeneral={isGeneral}
        isConnexionPage={isConnexionPage}
        isDashboardPage={isDashboardPage}
      >
        {children}
        <Outlet />
        {isDashboardPage && <Footer />}
      </Main>
    </Styled.RootWrapper>
  );
};
