import { Suspense as ReactSuspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as Styled from './style';

export const Suspense = ({ component }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <ReactSuspense
      fallback={
        <Styled.LoaderWrapper>
          <Styled.Spinner />
        </Styled.LoaderWrapper>
      }
    >
      {component}
    </ReactSuspense>
  );
};
