import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { AppContext } from 'context/AppContext';
import { logout } from 'actions/auth';
import { Navigation } from 'layouts/navigation';
import CloseSnackBarImg from 'assets/images/close-snack-bar.svg';
import ConnexionLogoImg from 'assets/images/connexion-logo.svg';
import ConnexionSmallLogoImg from 'assets/images/connexion-small-logo.svg';
import DeconnexionIcon from 'assets/images/deconnexion.svg';
import PrincipalLogoImg from 'assets/images/principal-logo.svg';
import { muiDeconnexionButton, muiDeconnexionIcon } from './style';
import * as Styled from './style';

const headerStyle = {
  general: {
    Header: Styled.GeneralHeader,
    Logo: Styled.PrincipalLogo,
    logoSrc: PrincipalLogoImg,
  },
  connexionPage: {
    Header: Styled.ConnexionHeader,
    Logo: Styled.ConnexionLogo,
    logoSrc: ConnexionLogoImg,
  },
  dashboard: {
    Header: Styled.DashboardHeader,
    Logo: Styled.DashboardLogo,
    logoSrc: PrincipalLogoImg,
  },
};

export const Header = ({ isConnexionPage, isDashboardPage }) => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const handleLogout = () => {
    appContext.dispatch(logout());
    navigate('/', { replace: true });
  };

  const selectedHeader = isConnexionPage
    ? 'connexionPage'
    : isDashboardPage
    ? 'dashboard'
    : 'general';

  const {
    Header: HeaderComponent,
    Logo: LogoComponent,
    logoSrc,
  } = headerStyle[selectedHeader];

  return (
    <HeaderComponent isMenuShown={appContext.setting.menuOpend}>
      {!isDashboardPage ? (
        <>
          <LogoComponent src={logoSrc} alt="Mobilize Logo" />
          {isConnexionPage && (
            <>
              <Styled.HeadingTextWrapper>
                <Styled.ConnexionHeading>
                  BIENVENUE{'\n'} SUR{' '}
                  <Styled.BreakOnMoblie>
                    VOTRE <strong>ESPACE CLIENT</strong>
                  </Styled.BreakOnMoblie>
                </Styled.ConnexionHeading>
              </Styled.HeadingTextWrapper>
              <Styled.ConnexionSmallLogo
                src={ConnexionSmallLogoImg}
                alt="Mobilize Logo"
              />
            </>
          )}
        </>
      ) : (
        <>
          <Styled.MenuLogoWrapper>
            <Link to="./">
              <LogoComponent src={logoSrc} alt="Mobilize Logo" />
            </Link>
            <IconButton onClick={appContext.closeMenu}>
              <Styled.IconImg src={CloseSnackBarImg} alt="close" />
            </IconButton>
          </Styled.MenuLogoWrapper>
          <Navigation onClick={appContext.closeMenu} />
          <Button
            variant="outlined"
            sx={muiDeconnexionButton}
            onClick={handleLogout}
          >
            <Box
              component="img"
              src={DeconnexionIcon}
              alt="#"
              sx={muiDeconnexionIcon}
            />
            Déconnexion
          </Button>
        </>
      )}
    </HeaderComponent>
  );
};
