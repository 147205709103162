import { toClamp } from 'assets/style/functions';
import { Colors } from 'assets/style/variables';
import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  border-top: ${toClamp(1)} solid #c5cad1;
  padding: ${toClamp(23)} 0;
  margin-top: ${toClamp(92)};
  font-family: Graphie, sans-serif;
`;

export const NavigationList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${toClamp(64)};
  list-style: none;
`;

export const NavigationItem = styled.li`
  position: relative;
  font-size: ${toClamp(15)};
  line-height: ${toClamp(20)};
  a {
    color: ${Colors.black};
    &.active {
      color: ${Colors.orange};
    }
  }
  & a:hover {
    color: ${Colors.orange};
  }
  &:not(:last-child):after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translate(-50%, -25%);
    left: calc(100% + ${toClamp(32)});
    width: ${toClamp(4)};
    height: ${toClamp(4)};
    border-radius: 50%;
    background-color: ${Colors.black};
  }
`;
