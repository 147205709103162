import styled from 'styled-components';
import { Colors, Fonts } from 'assets/style/variables';
import { mediaScreen, toClamp } from 'assets/style/functions';

export const Navigation = styled.nav`
  flex: 1;
`;
export const NavList = styled.ul`
  margin-top: ${toClamp(88)};
  gap: ${toClamp(36)};
  display: flex;
  flex-direction: column;
  ${mediaScreen(`margin-top: ${toClamp(112)};`, 768)};
  li {
    a {
      font-size: ${toClamp(22)};
      line-height: ${toClamp(25)};
      padding: 0;
      padding-left: ${toClamp(110)};
      width: ${toClamp(376)};
      height: ${toClamp(76)};
      border-radius: 0 ${toClamp(25)} ${toClamp(25)} 0;
      color: black;
      text-align: left;
      text-transform: initial;
      justify-content: flex-start;
      font-family: ${Fonts.regularMblz};
      &:hover, &.active {
        color: white;
        background-color: ${Colors.orange};
      }
      white-space: pre;
  &::before {
    content: '\00a0de ';
    white-space: pre;
  }
    }
  }
`;

export const muiHeaderAvatar = {
  width: toClamp(56),
  height: toClamp(56),
  backgroundColor: Colors.generalBgColor,
  position: 'absolute',
  left: toClamp(32),
};
