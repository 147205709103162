import { createContext, useEffect, useReducer } from 'react';
import AppReducer from 'reducers/app-reducer';
import { update } from 'actions/setting';

const initialState = {
  token: undefined,
  user: undefined,
  setting: { maintenanceModeEnabled: false, menuOpend: false },
  notification: 0,
};

export const AppContext = createContext(initialState);

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState, () => {
    const backup = localStorage.getItem('backup');
    return backup ? JSON.parse(backup) : initialState;
  });

  useEffect(() => {
    const { user, token, setting, notification } = state;
    localStorage.setItem(
      'backup',
      JSON.stringify({
        user,
        token,
        setting: { ...setting, menuOpend: false },
        notification,
      })
    );
  }, [state]);

  const openMenu = event => {
    event.stopPropagation();

    dispatch(update({ setting: { ...state.setting, menuOpend: true } }));
  };

  const closeMenu = event => {
    event.stopPropagation();

    dispatch(update({ setting: { ...state.setting, menuOpend: false } }));
  };

  return (
    <AppContext.Provider
      value={{
        user: state.user,
        token: state.token,
        setting: state.setting,
        notification: state.notification,
        dispatch,
        openMenu,
        closeMenu,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
