import { Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { headerNavContent } from 'assets/Lists';
import { toClamp } from 'assets/style/functions';
import { NavLink } from 'react-router-dom';
import * as Styled from './style';
import { muiHeaderAvatar } from './style';

export const Navigation = ({ onClick }) => {
  return (
    <Styled.Navigation>
      <Styled.NavList>
        {headerNavContent.map((option, index) => (
          <li key={index} onClick={onClick}>
            <Button component={NavLink} to={option.path}>
              <Avatar sx={muiHeaderAvatar}>
                <Box
                  component="img"
                  src={option.icon}
                  sx={{ height: toClamp(24) }}
                  alt=""
                />
              </Avatar>
              {option.content}
              {option.content2 && (
                <>
                  <br />
                  {option.content2}
                </>
              )}
            </Button>
          </li>
        ))}
      </Styled.NavList>
    </Styled.Navigation>
  );
};
