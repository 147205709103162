import Maintenance from 'assets/images/maintenance.png';
import { mediaScreen, toClamp } from 'assets/style/functions';
import { Colors } from 'assets/style/variables';
import styled from 'styled-components';

export const GeneralMain = styled.main`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${Colors.generalBgColor};
  text-align: center;
`;

export const UnderMaintenanceMain = styled.main`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 ${toClamp(111)};
  background-image: url(${Maintenance});
  background-repeat: no-repeat;
  background-position: right center;
  background-size: ${toClamp(848)} 100vh;
  background-color: ${Colors.generalBgColor};
  ${mediaScreen(
    `padding: 0 24px;
    align-items: center;
    text-align: center;
    background-image: none;
    h2 {
      line-height: 28px;
    }`,
    900
  )};
`;

export const ConnexionMain = styled.main`
  margin: auto;
  text-align: center;
  width: ${toClamp(663)};
  ${mediaScreen(`margin: ${toClamp(48)} auto;`, 768)};
  ${mediaScreen(`padding: 0 8px;`, 375)};
  .MuiFormLabel-root {
    ${mediaScreen(
      `font-size: 13px; transform: translate(13px, -7px) scale(0.75);`,
      1024
    )};
    ${mediaScreen(
      `font-size: 11px; transform: translate(13px, -6px) scale(0.75);`,
      768
    )};
  }
`;
export const DashboardMain = styled.main`
  flex: 1;
  min-height: 100vh;
  border-top-left-radius: ${toClamp(66)};
  overflow: hidden;
  background-color: ${Colors.generalBgColor};
  padding: ${toClamp(48)} ${toClamp(32)} 0 ${toClamp(40)};
  ${mediaScreen(
    `border-top-left-radius: 0;
    padding: ${toClamp(48)} ${toClamp(48)} 0;`,
    768
  )};
`;
