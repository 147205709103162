import { createTheme } from '@mui/material/styles';
import { Colors, Fonts } from './variables';
import { toClamp } from './functions';

export const MuiTheme = createTheme({
  components: {
    MuiSnackbar: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.primaryText,
          position: 'static',
          width: '100%',
          borderRadius: toClamp(20),
          marginBottom: toClamp(24),
          overflow: 'hidden',
          padding: `${toClamp(24)} ${toClamp(38)}`,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {},
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        message: {
          fontWeight: 'Regular',
          fontSize: toClamp(22),
          lineHeight: toClamp(25),
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            fontWeight: 400,
            fontSize: toClamp(18),
            height: toClamp(64),
            fontFamily: Fonts.regularMblz,
          },
          '& .MuiOutlinedInput-root': {
            '& > fieldset': {
              borderColor: Colors.gray,
            },
          },
          '& .MuiOutlinedInput-root:hover': {
            '& > fieldset': {
              borderColor: Colors.orange,
            },
          },
          '& .MuiInputLabel-root': {
            color: Colors.secondaryText,
            fontWeight: '400',
            fontFamily: Fonts.regularMblz,
            backgroundColor: Colors.white,
            paddingRight: '8px',
          },

          '& .MuiInputLabel-root.Mui-focused': {
            color: Colors.orange,
          },
          '& .MuiOutlinedInput-root.Mui-focused': {
            '& > fieldset': {
              borderColor: Colors.orange,
            },
          },

          '& .MuiOutlinedInput-root.Mui-error': {
            '& > fieldset': {
              borderColor: Colors.errorColor,
              borderWidth: '2px',
              backgroundColor: Colors.lightErrorColor,
            },
          },
          '& .MuiInputLabel-root.Mui-error': {
            color: Colors.errorColor,
          },

          '& .MuiInputLabel-root.Mui-focused.Mui-error': {
            color: Colors.errorColor,
          },
          '& .MuiFormHelperText-root.Mui-error': {
            color: Colors.errorColor,
            fontSize: toClamp(13),
            margin: toClamp(8),
            fontFamily: Fonts.regularMblz,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {},
        select: {},
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: Colors.orange,
        },
        asterisk: {
          display: 'none',
        },
      },
    },
  },
});
const muiButtonSharedStyle = {
  borderRadius: 0,
  fontWeight: 'bold',
  textTransform: 'none',
  ':hover': {
    backgroundColor: Colors.orange,
    color: 'white',
    borderColor: Colors.orange,
  },
};
const muiContainedBlackButton = {
  backgroundColor: 'black',
  color: 'white',
  border: 1,
  borderColor: 'black',
};
const muiDashboardButtonSharedStyle = {
  fontSize: toClamp(22),
  lineHeight: toClamp(25),
  padding: toClamp(16),
  fontFamily: Fonts.regularMblz,
  fontWeight: 'initial',
};
export const muiContainedDashboardSmallButton = {
  width: toClamp(223),
  ...muiContainedBlackButton,
  ...muiDashboardButtonSharedStyle,
  ...muiButtonSharedStyle,
};
export const muiSecondContainedDashboardSmallButton = {
  width: toClamp(223),
  ...muiContainedBlackButton,
  fontSize: toClamp(18),
  lineHeight: toClamp(21),
  padding: toClamp(18),
  fontFamily: Fonts.regularMblz,
  fontWeight: 'initial',
  ...muiButtonSharedStyle,
};
export const muiContainedDashboardBigButton = {
  ...muiContainedBlackButton,
  width: toClamp(303),
  fontSize: toClamp(18),
  lineHeight: toClamp(21),
  padding: toClamp(18),
  fontFamily: Fonts.regularMblz,
  fontWeight: 'initial',
  ...muiButtonSharedStyle,
};
const muiConnexionButtonSharedStyle = {
  width: toClamp(480),
  fontSize: toClamp(22),
  lineHeight: toClamp(25),
  padding: toClamp(16),
  fontFamily: Fonts.regularMblz,
  fontWeight: 'initial',
};
export const muiContainedConnexionButton = {
  marginTop: toClamp(40),
  ...muiContainedBlackButton,
  ...muiConnexionButtonSharedStyle,
  ...muiButtonSharedStyle,
};
export const muiOutlinedConnexionButton = {
  backgroundColor: 'transparent',
  color: Colors.orange,
  borderColor: Colors.orange,
  marginTop: toClamp(12),
  ...muiConnexionButtonSharedStyle,
  ...muiButtonSharedStyle,
};
export const muiBlueButtonLink = {
  fontSize: toClamp(18),
  lineHeight: toClamp(20),
  textDecoration: 'underline',
  color: Colors.skyBlue,
  display: 'block',
  textTransform: 'none',
  margin: `0 auto`,
  fontFamily: Fonts.boldMblz,
  fontWeight: 'initial',
  width: 'fit-content',
  ':hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
    color: Colors.orange,
  },
};
export const muiFullWidthAndHeight = {
  width: '100%',
  height: '100%',
};
