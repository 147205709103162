import { css } from 'styled-components';

// Druk Bold
import DrukBoldWoff2 from '../fonts/druk/druk-bold.woff2';
import DrukBoldWoff from '../fonts/druk/druk-bold.woff';
import DrukBoldTtf from '../fonts/druk/druk-bold.ttf';

// Graphie Regular
import GraphieRegularWoff2 from '../fonts/graphie/graphie-regular.woff2';
import GraphieRegularWoff from '../fonts/graphie/graphie-regular.woff';
import GraphieRegularTtf from '../fonts/graphie/graphie-regular.ttf';

// Graphie Semi-Bold
import GraphieSemiBoldWoff2 from '../fonts/graphie/graphie-semi-bold.woff2';
import GraphieSemiBoldWoff from '../fonts/graphie/graphie-semi-bold.woff';
import GraphieSemiBoldTtf from '../fonts/graphie/graphie-semi-bold.ttf';

// Graphie Bold
import GraphieBoldWoff2 from '../fonts/graphie/graphie-bold.woff2';
import GraphieBoldWoff from '../fonts/graphie/graphie-bold.woff';
import GraphieBoldTtf from '../fonts/graphie/graphie-bold.ttf';

// Graphie Extra Bold
import GraphieExtraBoldWoff2 from '../fonts/graphie/graphie-extra-bold.woff2';
import GraphieExtraBoldWoff from '../fonts/graphie/graphie-extra-bold.woff';
import GraphieExtraBoldTtf from '../fonts/graphie/graphie-extra-bold.ttf';

// Graphie W05 Light
import GraphieW05LightWoff2 from '../fonts/graphie-w05/graphie-w05-light.woff2';
import GraphieW05LightWoff from '../fonts/graphie-w05/graphie-w05-light.woff';
import GraphieW05LightTtf from '../fonts/graphie-w05/graphie-w05-light.ttf';

// Graphie W05 Regular
import GraphieW05RegularWoff2 from '../fonts/graphie-w05/graphie-w05-regular.woff2';
import GraphieW05RegularWoff from '../fonts/graphie-w05/graphie-w05-regular.woff';
import GraphieW05RegularTtf from '../fonts/graphie-w05/graphie-w05-regular.ttf';

// Graphie W05 Semi-Bold
import GraphieW05SemiBoldWoff2 from '../fonts/graphie-w05/graphie-w05-semi-bold.woff2';
import GraphieW05SemiBoldWoff from '../fonts/graphie-w05/graphie-w05-semi-bold.woff';
import GraphieW05SemiBoldTtf from '../fonts/graphie-w05/graphie-w05-semi-bold.ttf';

// Graphie W05 Bold
import GraphieW05BoldWoff2 from '../fonts/graphie-w05/graphie-w05-bold.woff2';
import GraphieW05BoldWoff from '../fonts/graphie-w05/graphie-w05-bold.woff';
import GraphieW05BoldTtf from '../fonts/graphie-w05/graphie-w05-bold.ttf';

// MBLZ Regular
import MblzRegularWoff2 from '../fonts/mblz/MBLZ-Text.woff2';
import MblzRegularWoff from '../fonts/mblz/MBLZ-Text.woff';
import MblzRegularTtf from '../fonts/mblz/MBLZ-Text.ttf';

// MBLZ Bold
import MblzBoldWoff2 from '../fonts/mblz/MBLZ-Bold.woff2';
import MblzBoldWoff from '../fonts/mblz/MBLZ-Bold.woff';
import MblzBoldTtf from '../fonts/mblz/MBLZ-Bold.ttf';

// MBLZ Extra Bold
import MblzExtraBoldWoff2 from '../fonts/mblz/MBLZ-Title.woff2';
import MblzExtraBoldWoff from '../fonts/mblz/MBLZ-Title.woff';
import MblzExtraBoldTtf from '../fonts/mblz/MBLZ-Title.ttf';

const fonts = [
  {
    fontFamily: 'Druk',
    fontWeight: 700,
    woff2Src: DrukBoldWoff2,
    woffSrc: DrukBoldWoff,
    ttfSrc: DrukBoldTtf,
  },
  {
    fontFamily: 'Graphie',
    fontWeight: 400,
    woff2Src: GraphieRegularWoff2,
    woffSrc: GraphieRegularWoff,
    ttfSrc: GraphieRegularTtf,
  },
  {
    fontFamily: 'Graphie',
    fontWeight: 600,
    woff2Src: GraphieSemiBoldWoff2,
    woffSrc: GraphieSemiBoldWoff,
    ttfSrc: GraphieSemiBoldTtf,
  },
  {
    fontFamily: 'Graphie',
    fontWeight: 700,
    woff2Src: GraphieBoldWoff2,
    woffSrc: GraphieBoldWoff,
    ttfSrc: GraphieBoldTtf,
  },
  {
    fontFamily: 'Graphie',
    fontWeight: 800,
    woff2Src: GraphieExtraBoldWoff2,
    woffSrc: GraphieExtraBoldWoff,
    ttfSrc: GraphieExtraBoldTtf,
  },
  {
    fontFamily: 'GraphieW05-Light',
    fontWeight: 400,
    woff2Src: GraphieW05LightWoff2,
    woffSrc: GraphieW05LightWoff,
    ttfSrc: GraphieW05LightTtf,
  },
  {
    fontFamily: 'GraphieW05-Regular',
    fontWeight: 400,
    woff2Src: GraphieW05RegularWoff2,
    woffSrc: GraphieW05RegularWoff,
    ttfSrc: GraphieW05RegularTtf,
  },
  {
    fontFamily: 'Graphie W05',
    fontWeight: 600,
    woff2Src: GraphieW05SemiBoldWoff2,
    woffSrc: GraphieW05SemiBoldWoff,
    ttfSrc: GraphieW05SemiBoldTtf,
  },
  {
    fontFamily: 'GraphieW05-Bold',
    fontWeight: 400,
    woff2Src: GraphieW05BoldWoff2,
    woffSrc: GraphieW05BoldWoff,
    ttfSrc: GraphieW05BoldTtf,
  },
  {
    fontFamily: 'Mblz-Regular',
    fontWeight: 400,
    woff2Src: MblzRegularWoff2,
    woffSrc: MblzRegularWoff,
    ttfSrc: MblzRegularTtf,
  },
  {
    fontFamily: 'Mblz-Bold',
    fontWeight: 700,
    woff2Src: MblzBoldWoff2,
    woffSrc: MblzBoldWoff,
    ttfSrc: MblzBoldTtf,
  },
  {
    fontFamily: 'Mblz-Extra-Bold',
    fontWeight: 800,
    woff2Src: MblzExtraBoldWoff2,
    woffSrc: MblzExtraBoldWoff,
    ttfSrc: MblzExtraBoldTtf,
  },
];

const createFontFaces = fonts
  .map(
    font => `
  @font-face {
    font-family: '${font.fontFamily}';
    src: url(${font.woff2Src}) format('woff2'),
    url(${font.woffSrc}) format('woff'),
    url(${font.ttfSrc}) format('ttf');
    font-weight: ${font.fontWeight};
  }
`
  )
  .join('\n');

export const fontFaces = css`
  ${createFontFaces}
`;
