import Button from "@mui/material/Button";
import { muiContainedConnexionButton } from "assets/style/mui-theme";
import { Link } from "react-router-dom";
import { insertLineBreaks } from "utils/insert-line-breaks";
import { ConnexionIntro } from "../connexion-intro";
import { OrangeCircle } from "../orange-circle";
import * as Styled from "./style";

export const Success = ({
  isConnexion,
  email,
  passwordUpdated,
  value,
  to,
  isEmail,
  isSav,
  isReclamation,
  isDemandeInfo,
  isImpayes,
  type,
  requestId,
  contractId,
  noBtn,
  isSuccessEmail,
}) => {
  const passWordOrAccount = passwordUpdated ? "Votre mot de passe a été\nmis à jour avec succès" : "Votre compte a été créé avec succès";
  const updateOrCreation = passwordUpdated ? "mis à jour" : "créé";

  return (
    <Styled.SuccessWrapper isConnexion={isConnexion} isEmail={isEmail}>
      {isConnexion && (
        <ConnexionIntro
          title={
            isEmail ? "Vérification de l'email" : insertLineBreaks(passWordOrAccount)
          }
        />
      )}
      <OrangeCircle />
      {isSav && (
        <Styled.SuccessParagraph>
          Votre demande SAV inhérente au {type} a été enregistrée avec succès
          sous le
          <Styled.SuccessSpan>{`N° ${requestId}`}</Styled.SuccessSpan> et sera
          traitée dans les plus brefs délais.
        </Styled.SuccessParagraph>
      )}
      {isDemandeInfo && (
        <Styled.SuccessParagraph>
          Votre demande d’information inhérente au dossier
          <Styled.SuccessSpan>{contractId}</Styled.SuccessSpan>a été enregistrée
          avec succès sous le
          <Styled.SuccessSpan>{`N° ${requestId}`}</Styled.SuccessSpan>.
        </Styled.SuccessParagraph>
      )}
      {isReclamation && (
        <Styled.SuccessParagraph>
          Votre réclamation inhérente au dossier
          <Styled.SuccessSpan>{contractId}</Styled.SuccessSpan>a été enregistrée
          avec succès sous le
          <Styled.SuccessSpan>{`N° ${requestId}`}</Styled.SuccessSpan> et sera
          traitée dans les plus brefs délais.
        </Styled.SuccessParagraph>
      )}
      {isImpayes && (
        <Styled.SuccessParagraph>
          Le paiement de votre impayé avec
          <Styled.SuccessSpan>l'ID numéro 1</Styled.SuccessSpan>a été effectué
          avec succès.
          <br />
          L'avis de paiement vous a été envoyé par email.
        </Styled.SuccessParagraph>
      )}
      {isEmail && (
        <>
          <Styled.SuccessEmailParagraph>
            Nous allons envoyer un email à
            <Styled.SuccessEmailSpan>{email}</Styled.SuccessEmailSpan>
          </Styled.SuccessEmailParagraph>
        </>
      )}
      {isSuccessEmail && (
        <>
          <Styled.SuccessEmailParagraphBold>
            Nous allons envoyer un email à
            <Styled.SuccessEmailStrong>{email}</Styled.SuccessEmailStrong>
          </Styled.SuccessEmailParagraphBold>
        </>
      )}
      {!noBtn && (
        <Button
          variant="contained"
          component={Link}
          to={to}
          sx={muiContainedConnexionButton}
        >
          {value}
        </Button>
      )}
    </Styled.SuccessWrapper>
  );
};
