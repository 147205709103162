import { NavLink } from 'react-router-dom';
import { content } from './content';
import * as Styled from './style';
const { generalConditions, help } = content;
export const Footer = () => {
  return (
    <Styled.FooterWrapper>
      <nav>
        <Styled.NavigationList>
          <Styled.NavigationItem>
            <NavLink to={generalConditions.path}>
              {generalConditions.content}
            </NavLink>
          </Styled.NavigationItem>
          <Styled.NavigationItem>
            <NavLink to={help.path}>{help.content}</NavLink>
          </Styled.NavigationItem>
        </Styled.NavigationList>
      </nav>
    </Styled.FooterWrapper>
  );
};
