const AppReducer = (state = {}, action) => {
    switch (action.type) {
        case 'LOGIN': {
            const { token, user } = action.payload;
            return {
                ...state,
                token,
                user
            };
        }
        case 'REGISTER': {
            const { user } = action.payload;
            return {
                ...state,
                user
            };
        }
        case 'UPDATE': {
            const { user } = action.payload;
            return {
                ...state,
                user
            };
        }
        case 'VERIFIED': {
            return {
                ...state,
                token: undefined,
                user: undefined,
            };
        }
        case 'LOGOUT': {
            return {
                ...state,
                token: undefined,
                user: undefined,
            };
        }
        case 'NOTIFICATION_PUSH': {
            const { notification } = action.payload;
            return {
                ...state,
                notification
            };
        }
        case 'NOTIFICATION_CLEAR': {
            return {
                ...state,
                notification: 0
            };
        }
        case 'CONNECTIVITY_CHANGE': {
            const { connected } = action.payload;
            return {
                ...state,
                connected
            };
        }
        case 'UPDATE_SETTING': {
            return {
                ...state,
                setting: { ...state.setting, ...action.payload.setting },
            };
        }
        default: {
            return state;
        }
    }
};

export default AppReducer;