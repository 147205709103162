import * as Styled from './style';

export const ConnexionIntro = ({ title, children }) => {
  return (
    <>
      <Styled.ConnexionIntroHeading>{title}</Styled.ConnexionIntroHeading>
      {children && (
        <Styled.ConnexionIntroParagraph>
          {children}
        </Styled.ConnexionIntroParagraph>
      )}
    </>
  );
};
