import WhiteBoxIcon55 from "assets/images/Image-55.svg";
import WhiteBoxIcon56 from "assets/images/Image-56.svg";
import WhiteBoxIcon1 from "assets/images/Image-1.svg";
import HeaderIcon3 from "assets/images/Image-131.svg";
import HeaderIcon132 from "assets/images/Image-132.svg";
import HeaderIcon133 from "assets/images/Image-133.svg";
import HeaderIcon134 from "assets/images/Image-134.svg";
import HeaderIcon1 from "assets/images/Image-2.svg";
import WhiteBoxIcon3 from "assets/images/Image-24.svg";
import WhiteBoxIcon4 from "assets/images/Image-25.svg";
import WhiteBoxIcon5 from "assets/images/Image-26.svg";
import WhiteBoxIcon6 from "assets/images/Image-27.svg";
import WhiteBoxIcon7 from "assets/images/Image-28.svg";
import WhiteBoxIcon8 from "assets/images/Image-29.svg";
import HeaderIcon2 from "assets/images/Image-3.svg";
import WhiteBoxIcon9 from "assets/images/Image-30.svg";
import HeaderIcon4 from "assets/images/Image-5.svg";
import WhiteBoxIcon13 from "assets/images/Image-51.svg";
import WhiteBoxIcon12 from "assets/images/Image-52.svg";
import WhiteBoxIcon10 from "assets/images/Image-53.svg";
import WhiteBoxIcon11 from "assets/images/Image-54.svg";
import HeaderIcon5 from "assets/images/Image-6.svg";
import auteurIcon from "assets/images/auteur-2-icon.svg";
import ContactIcon from "assets/images/contact.svg";
import FaqIcon from "assets/images/faq.svg";

export const mesContratsBoxesContent = [
  {
    key: "id",
    iconImg: WhiteBoxIcon4,
    title: "N° dossier",
    content: "",
  },
  {
    key: "owner.lastname",
    iconImg: WhiteBoxIcon1,
    title: "Nom",
    content: "",
  },
  {
    key: "owner.firstname",
    iconImg: WhiteBoxIcon3,
    title: "Prénom",
    content: "",
  },
  {
    key: "product",
    iconImg: WhiteBoxIcon9,
    title: "Produit",
    content: "",
  },
  {
    key: "status",
    iconImg: WhiteBoxIcon5,
    title: "Statut",
    content: "",
  },
  {
    key: "amountFinanced",
    iconImg: WhiteBoxIcon6,
    title: "Montant finance",
    content: "",
  },
  {
    key: "monthlyPayment",
    iconImg: WhiteBoxIcon7,
    title: "Mensualite hors services",
    content: "",
  },
  // {
  //   key: "insuranceCharges",
  //   iconImg: WhiteBoxIcon8,
  //   title: "Frais d’assurance",
  //   content: "",
  // },
  {
    key: "duration",
    iconImg: WhiteBoxIcon8,
    title: "Durée",
    content: "",
  },
  {
    key: "dueDate",
    iconImg: HeaderIcon132,
    title: "Date de fin d'échéance",
    content: "",
  },
  // {
  //   key: "remainingAmount",
  //   iconImg: HeaderIcon133,
  //   title: "Montant restant dû",
  //   content: "",
  // },
  // {
  //   key: 'collectionDate',
  //   iconImg: HeaderIcon134,
  //   title: 'Date de prélèvement',
  //   content: '',
  // },
];
export const demandeDetailBoxesContent = [
  {
    key: "id",
    iconImg: WhiteBoxIcon10,
    title: "N° DEMANDE",
    content: "",
  },
  {
    key: "contractId",
    iconImg: WhiteBoxIcon4,
    title: "N° DOSSIER",
    content: "",
  },
  {
    key: "type",
    iconImg: WhiteBoxIcon11,
    title: "Type",
    content: "",
  },
  {
    key: "status",
    iconImg: WhiteBoxIcon5,
    title: "STATUT",
    content: "",
  },
  {
    key: "category",
    iconImg: WhiteBoxIcon9,
    title: "CATEGORIE",
    content: "",
  },
  {
    key: "createdAt",
    iconImg: WhiteBoxIcon12,
    title: "DATE DE CREATION",
    content: "",
  },
  {
    key: "owner.fullname",
    iconImg: WhiteBoxIcon1,
    title: "CLIENT",
    content: "",
  },
  {
    key: "updatedAt",
    iconImg: WhiteBoxIcon13,
    title: "DERNIERE\n MISE A JOUR",
    content: "",
  },
];
export const fillSelect = {
  label: "Sélectionner",
  options: ["15488600", "12345678", "56437823", "10293847"],
};
export const dossier = {
  label: "N°dossier",
  options: ["15488600", "12345678", "56437823", "10293847"],
};
export const statut = {
  label: "Statut",
  options: ["15488600", "12345678", "56437823", "10293847"],
};
export const typeSelect = {
  label: "Type",
  options: ["15488600", "12345678", "56437823", "10293847"],
};
export const demande = {
  label: "N° demande",
  options: ["15488600", "12345678", "56437823", "10293847"],
};
export const savFormControlLabel = [
  {
    value: "tableau-amortissement",
    label: "Tableau d’amortissement",
  },
  {
    value: "attestation-mainlevee",
    label: "Attestation de mainlevée",
  },
  {
    value: "situation-rachat",
    label: "Situation de rachat par anticipation",
  },
  {
    value: "autorisation-territoire",
    label: "Autorisation pour quitter le territoire",
  },
  {
    value: "changement-domiciliation-bancaire",
    label: "Changement de domiciliation bancaire",
  },
  {
    value: "hangement-date-prelevement",
    label: "hangement de date de prélèvement",
  },
  {
    value: "hangement-adresse",
    label: "hangement d’adresse",
  },
  {
    value: "changement-date-prelevement",
    label: "Changement de date de prélèvement",
  },
  {
    value: "changement-adresse",
    label: "Changement d’adresse",
  },
  {
    value: "demande-information",
    label: "Demande d’information",
  },
];
export const reclamationsFormControlLabel = [
  {
    value: "demande-sav",
    label: "Demande SAV",
  },
  {
    value: "reclamation",
    label: "Réclamation",
  },
];
export const headerNavContent = [
  {
    icon: HeaderIcon1,
    content: "Mes contrats",
    path: "/mes-contrats",
  },
  {
    icon: HeaderIcon2,
    content: "Initier une",
    content2: "Demande SAV",
    path: "/demande-sav",
  },
  {
    icon: HeaderIcon3,
    content: "Réclamation",
    path: "/reclamation",
  },
  {
    icon: HeaderIcon4,
    content: "Historique",
    content2: "de mes demandes",
    path: "/historique",
  },
  // {
  //   icon: HeaderIcon5,
  //   content: "Réglement des impayés",
  //   path: "/reglement-impayes",
  // },
];
export const profileList = [
  {
    icon: WhiteBoxIcon55,
    content: "Modifier mon profile",
    path: "/mon-profil",
  },
  {
    icon: WhiteBoxIcon56,
    content: "Besoin d’aide",
    path: "/espace-aide",
  },
];
export const notificationsList = [
  {
    title: "Demande SAV N°21",
    time: "Il y a 5 heures",
    path: "/mes-notifications",
    description:
      "Votre demande de tableau d'amortissement avec l'ID numéro 26 est en cours de traitement",
  },
  {
    title: "Demande SAV N°20",
    time: "Il y a 1 jour",
    path: "/mes-notifications",
    description:
      "Votre demande de tableau d'amortissement avec l'ID numéro 26 est en cours de traitement",
  },
  {
    title: "Demande SAV N°19",
    time: "Il y a 1 semaine",
    path: "/mes-notifications",
    description:
      "Votre demande de tableau d'amortissement avec l'ID numéro 26 est en cours de traitement Votre demande de tableau d'amortissement avec l'ID numéro 26 est en ",
  },
];
export const MesNotificationsContent = [
  {
    day: "Aujourd'hui",
    notifications: [
      {
        title: "Demande SAV N°21",
        time: "Il y a 5 heures",
        path: "/mes-notifications",
        description:
          "Votre demande de tableau d'amortissement avec l'ID numéro 26 est en cours de traitement Votre demande de tableau d'amortissement avec l'ID numéro 26 est en ",
      },
      {
        title: "Demande SAV N°21",
        time: "Il y a 5 heures",
        path: "/mes-notifications",
        description:
          "Votre demande de tableau d'amortissement avec l'ID numéro 26 est en cours de traitement Votre demande de tableau d'amortissement avec l'ID numéro 26 est en ",
      },
      {
        title: "Demande SAV N°21",
        time: "Il y a 5 heures",
        path: "/mes-notifications",
        description:
          "Votre demande de tableau d'amortissement avec l'ID numéro 26 est en cours de traitement Votre demande de tableau d'amortissement avec l'ID numéro 26 est en ",
      },
    ],
  },
  {
    day: "Hier",
    notifications: [
      {
        title: "Demande SAV N°21",
        time: "Il y a 5 heures",
        path: "/mes-notifications",
        description:
          "Votre demande de tableau d'amortissement avec l'ID numéro 26 est en cours de traitement Votre demande de tableau d'amortissement avec l'ID numéro 26 est en ",
      },
      {
        title: "Demande SAV N°21",
        time: "Il y a 5 heures",
        path: "/mes-notifications",
        description:
          "Votre demande de tableau d'amortissement avec l'ID numéro 26 est en cours de traitement Votre demande de tableau d'amortissement avec l'ID numéro 26 est en ",
      },
    ],
  },
  {
    day: "13 Mar 2023",
    notifications: [
      {
        title: "Demande SAV N°21",
        time: "Il y a 5 heures",
        path: "/mes-notifications",
        description:
          "Votre demande de tableau d'amortissement avec l'ID numéro 26 est en cours de traitement Votre demande de tableau d'amortissement avec l'ID numéro 26 est en ",
      },
      {
        title: "Demande SAV N°21",
        time: "Il y a 5 heures",
        path: "/mes-notifications",
        description:
          "Votre demande de tableau d'amortissement avec l'ID numéro 26 est en cours de traitement Votre demande de tableau d'amortissement avec l'ID numéro 26 est en ",
      },
    ],
  },
];
export const aideContent = [
  {
    icon: FaqIcon,
    title: "FAQ",
    content:
      "Découvrez les réponses à toutes vos questions dans notre FAQ complète ! De l'essentiel aux détails les plus précis, nous avons rassemblé toutes les informations dont vous avez besoin pour faciliter votre expérience et vous éclairer sur notre produit/service.",
    content2:
      "Ne manquez pas l'occasion de lever tous vos doutes et de bénéficier d'une meilleure compréhension grâce à notre section FAQ !",
    btnTitle: "Consultez les FAQs",
    path: "https://www.mobilize-fs.ma/foire-aux-question",
  },
  {
    icon: ContactIcon,
    title: "Contact",
    content:
      "Votre opinion est inestimable pour nous ! N'hésitez pas à nous contacter en remplissant notre formulaire de contact.",
    content2:
      "Que ce soit pour des questions, des suggestions, ou des retours, nous sommes impatients de vous entendre. Votre feedback nous permettra de vous offrir un meilleur service et de répondre au mieux à vos besoins. Alors, ne tardez plus, nous avons hâte de vous lire !",
    btnTitle: "Contactez-nous",
    path: "https://www.mobilize-fs.ma/contact",
  },
];
export const reglementImpayesTableHeader = [
  "ID",
  "N° dossier",
  "Client",
  "Statut",
  "Echeance",
  "Restant dû",
  "Réglement",
];

export const reglementImpayesRowsContent = [
  {
    id: "9",
    NDossier: "15488600",
    Client: "Jeanne Henderson",
    Statut: "En attente",
    Echeance: "16/05/2023",
    RestantDû: "1 666.67",
    isPaid: true,
    ButtonTitle: "Payer en ligne",
    ButtonHref: "/reglement-impayes/successful_payment",
  },
  {
    id: "8",
    NDossier: "15488600",
    Client: "Jeanne Henderson",
    Statut: "En attente",
    Echeance: "16/05/2023",
    RestantDû: "1 666.67",
    isPaid: true,
    ButtonTitle: "Payer en ligne",
    ButtonHref: "/reglement-impayes/successful_payment",
  },
  {
    id: "7",
    NDossier: "15488600",
    Client: "Jeanne Henderson",
    Statut: "Réglé",
    Echeance: "16/05/2023",
    RestantDû: "1 666.67",
    isPaid: false,
    ButtonTitle: null,
    ButtonHref: null,
  },
  {
    id: "6",
    NDossier: "15488600",
    Client: "Jeanne Henderson",
    Statut: "En attente",
    Echeance: "16/05/2023",
    RestantDû: "1 666.67",
    isPaid: true,
    ButtonTitle: "Payer en ligne",
    ButtonHref: "/reglement-impayes/successful_payment",
  },
  {
    id: "5",
    NDossier: "15488600",
    Client: "Jeanne Henderson",
    Statut: "En attente",
    Echeance: "16/05/2023",
    RestantDû: "1 666.67",
    isPaid: true,
    ButtonTitle: "Payer en ligne",
    ButtonHref: "/reglement-impayes/successful_payment",
  },
  {
    id: "4",
    NDossier: "15488600",
    Client: "Jeanne Henderson",
    Statut: "Réglé",
    Echeance: "16/05/2023",
    RestantDû: "1 666.67",
    isPaid: false,
    ButtonTitle: null,
    ButtonHref: null,
  },
  {
    id: "3",
    NDossier: "15488600",
    Client: "Jeanne Henderson",
    Statut: "En attente",
    Echeance: "16/05/2023",
    RestantDû: "1 666.67",
    isPaid: true,
    ButtonTitle: "Payer en ligne",
    ButtonHref: "/reglement-impayes/successful_payment",
  },
  {
    id: "2",
    NDossier: "15488600",
    Client: "Jeanne Henderson",
    Statut: "En attente",
    Echeance: "16/05/2023",
    RestantDû: "1 666.67",
    isPaid: false,
    ButtonTitle: null,
    ButtonHref: null,
  },
  {
    id: "1",
    NDossier: "15488600",
    Client: "Jeanne Henderson",
    Statut: "Réglé",
    Echeance: "16/05/2023",
    RestantDû: "1 666.67",
    isPaid: true,
    ButtonTitle: "Payer en ligne",
    ButtonHref: "/reglement-impayes/successful_payment",
  },
];
export const historiqueTableHeader = [
  "N° demande",
  "N° dossier",
  "Type",
  "Statut",
  "Catégorie",
  "Détail",
];

export const historiqueRowsContent = [
  {
    id: "1",
    NDossier: "15488500",
    Type: "Tableau d’amortissement",
    Statut: "Nouveau",
    Categorie: "Demande SAV",
    DetailHref: "/demande-sav/request-details",
  },
  {
    id: "2",
    NDossier: "15488601",
    Type: "Réclamation",
    Statut: "Fermé",
    Categorie: "Réclamation",
    DetailHref: "/reclamation/request-details",
  },
  {
    id: "3",
    NDossier: "15488602",
    Type: "Attestation de mainlevée",
    Statut: "En cours",
    Categorie: "Demande d’info",
    DetailHref: "/demande-sav/request-details",
  },
  {
    id: "4",
    NDossier: "15488603",
    Type: "Changement d’adresse",
    Statut: "Fermé",
    Categorie: "Réclamation",
    DetailHref: "/reclamation/request-details",
  },
  {
    id: "5",
    NDossier: "15488604",
    Type: "Tableau d’amortissement",
    Statut: "En cours",
    Categorie: "Demande d’info",
    DetailHref: "/demande-sav/request-details",
  },
  {
    id: "6",
    NDossier: "15488605",
    Type: "Réclamation",
    Statut: "Nouveau",
    Categorie: "Demande SAV",
    DetailHref: "/demande-sav/request-details",
  },
  {
    id: "7",
    NDossier: "15488606",
    Type: "Attestation de mainlevée",
    Statut: "En cours",
    Categorie: "Réclamation",
    DetailHref: "/reclamation/request-details",
  },
  {
    id: "8",
    NDossier: "15488607",
    Type: "Changement d’adresse",
    Statut: "Nouveau",
    Categorie: "Demande d’info",
    DetailHref: "/demande-sav/request-details",
  },
  {
    id: "9",
    NDossier: "15488608",
    Type: "Tableau d’amortissement",
    Statut: "Fermé",
    Categorie: "Demande SAV",
    DetailHref: "/demande-sav/request-details",
  },
  {
    id: "10",
    NDossier: "15488609",
    Type: "Réclamation",
    Statut: "Nouveau",
    Categorie: "Réclamation",
    DetailHref: "/reclamation/request-details",
  },
  {
    id: "21",
    NDossier: "15488600",
    Type: "Tableau d’amortissement",
    Statut: "Nouveau",
    Categorie: "Demande SAV",
    DetailHref: "/demande-sav/request-details",
  },
];

export const attachedFilesTableHeader = [
  "Nom du fichier",
  "Type",
  "Taille",
  "Auteur",
  "Date de \n téléchargement",
  "Action",
];

export const attachedFilesRowsContent = [
  {
    nomDuFichier: "Tableau d’amortissement",
    type: "PDF",
    taille: "9MB",
    auteur: "Votre gestionnaire",
    auteurImg: auteurIcon,
    dateDeTelechargement: "02/02/2023",
    actionPath: "/file.pdf",
  },
  {
    nomDuFichier: "Tableau d’amortissement",
    type: "PDF",
    taille: "9MB",
    auteur: "Votre gestionnaire",
    auteurImg: auteurIcon,
    dateDeTelechargement: "02/02/2023",
    actionPath: "/file.pdf",
  },
  {
    nomDuFichier: "Tableau d’amortissement",
    type: "PDF",
    taille: "9MB",
    auteur: "Votre gestionnaire",
    auteurImg: auteurIcon,
    dateDeTelechargement: "02/02/2023",
    actionPath: "/file.pdf",
  },
];
export const attachedFiles = [
  "CIN",
  "Carte Gris",
  "Bordereau de règlement en cas de RAPT",
  "Dernier relevé bancaire ou bulletin de paie",
];
export const justCinAttachedFiles = ["CIN"];
