import { AppContext } from "context/AppContext";
import { useContext, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

export const PrivateRoute = ({ children }) => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // if (appContext?.user?.needToChangePassword) {
    //   navigate("/mon-profil");
    // }
  }, [appContext, navigate]);

  if (!appContext.token) {
    return <Navigate to="/sign-in" replace state={{ from: location }} />;
  }

  return children;
};
