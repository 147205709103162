import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AppContext } from 'context/AppContext';

export const PublicRoute = ({ children }) => {
    const appContext = useContext(AppContext);
    const location   = useLocation();

    if (appContext?.token) {
        return <Navigate to="/" replace state={{ from: location }} />;
    }

    return children;
}