import { toClamp } from 'assets/style/functions';
import { Colors } from 'assets/style/variables';
import styled, { keyframes } from 'styled-components';

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const Spinner = styled.div`
  border: ${toClamp(12)} solid ${Colors.lightErrorColor};
  border-top-color: ${Colors.orange};
  border-radius: 50%;
  width: ${toClamp(64)};
  height: ${toClamp(64)};
  animation: ${spinAnimation} 1s linear infinite;
`;
