export const login = (payload) => ({
    type: 'LOGIN',
    payload,
});

export const register = (payload) => ({
    type: 'REGISTER',
    payload,
});

export const verified = () => ({
    type: 'VERIFIED',
});

export const logout = () => ({
    type: 'LOGOUT',
});