import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SuccessImg from 'assets/images/success.svg';
import Incomplete from 'assets/images/incomplete_icon.svg'
import Progress from 'assets/images/progress_icon.svg'
import { svgIcon } from 'utils/svg-icon';
import { muiFullWidthAndHeight } from 'assets/style/mui-theme';
import { Link } from 'react-router-dom';
import * as Styled from './style';
import { muiOrangeCircleButton } from './style';

export const OrangeCircle = ({
  iconImg,
  path,
  onChange,
  isWebCam,
  onClick,
  reclamationStatus
}) => {
  return (
    <>
      {iconImg ? (
        <Styled.OrangeCircleWrapper hasIconImg={iconImg}>
          {path ? (
            <Button
              variant="outlined"
              component={Link}
              to={path}
              startIcon={svgIcon(iconImg)}
              sx={muiOrangeCircleButton}
            />
          ) : (
            <>
              {!isWebCam ? (
                <Button
                  variant="contained"
                  component="label"
                  startIcon={svgIcon(iconImg)}
                  sx={muiOrangeCircleButton}
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={onChange}
                  />
                </Button>
              ) : (
                <Button
                  variant="contained"
                  startIcon={svgIcon(iconImg)}
                  onClick={onClick}
                  sx={muiOrangeCircleButton}
                />
              )}
            </>
          )}
        </Styled.OrangeCircleWrapper>
      ) : (
        <Styled.OrangeCircleWrapper>
         {reclamationStatus ?
         (
            reclamationStatus=='new' || reclamationStatus=='ongoing' ?
            <Box
              component="img"
              src={Progress}
              sx={muiFullWidthAndHeight}
              alt="OnGoing"
              />:
              <Box
              component="img"
              src={Incomplete}
              sx={muiFullWidthAndHeight}
              alt="Incomplet"
              />)
            :<Box
              component="img"
              src={SuccessImg}
              sx={muiFullWidthAndHeight}
              alt="Success"
              />
         }
        </Styled.OrangeCircleWrapper>
      )}
    </>
  );
};
